import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Services from './components/Services';
import Abooutus from './components/About_us'; // Correct import spelling
import Menu from './components/Menu'; 
import EcommercePage from './components/E_Commerce_Page'; 
import SocialMediaMarketingPage from './components/Social_Media_Marketing_Page';
import MarketingAdvicePage from './components/Marketing_Advice_Page';
import WebDevelopmentPage from './components/Web_Development_Page';
import VisualEngineeringPage from './components/Visual_Engineering_Page';
import ApplicationDevelopmentPage from './components/Application_Development_Page';
import Popup from './components/Popup'; // Import the Popup component
import TermsOfUsePage from './components/Terms_of_use'; // Import the TermsOfUsePage component
import { LocaleProvider } from './locales/LocaleContext'; 
import localizationEn from './locales/en.json'; 
import localizationSr from './locales/sr.json'; 
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import '../src/Stylesheets/Popup.module.css'; // Import the Popup.css file

const resources = {
  en: { translation: localizationEn },
  sr: { translation: localizationSr }
};

const AppWrapper = () => {
  const [currentLocale, setCurrentLocale] = useState('en');
  const location = useLocation(); // Use the useLocation hook

  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: currentLocale,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });

  return (
    <LocaleProvider localization={resources[currentLocale]}>
      <div className="App">
        <Popup currentLocale={currentLocale} currentPath={location.pathname} setCurrentLocale={setCurrentLocale}/> {/* Pass currentPath to Popup */}
        <Header currentLocale={currentLocale} setCurrentLocale={setCurrentLocale} />
        <main>
          <Routes>
            <Route path="/services" element={<Services currentLocale={currentLocale} localization={resources} />} />
            <Route path="/aboutus" element={<Abooutus  currentLocale={currentLocale}/>} /> {/* Updated route */}
            <Route path="/menu" element={<Menu currentLocale={currentLocale} localization={resources}/>} />
            <Route path="/ecommerce-page" element={<EcommercePage  currentLocale={currentLocale}/>} /> 
            <Route path="/socialmediamarketing-page" element={<SocialMediaMarketingPage currentLocale={currentLocale}/>} />
            <Route path="/marketingadvice-page" element={<MarketingAdvicePage currentLocale={currentLocale}/>} />
            <Route path="/WebDevelopmentPage" element={<WebDevelopmentPage currentLocale={currentLocale}/>} />
            <Route path="/VisualEngineeringPage" element={<VisualEngineeringPage currentLocale={currentLocale}/>} />
            <Route path="/ApplicationDevelopmentPage" element={<ApplicationDevelopmentPage currentLocale={currentLocale}/>} />
            <Route path="/terms-of-use" element={<TermsOfUsePage currentLocale={currentLocale}/>} /> {/* Add the route for TermsOfUsePage */}
            <Route path="/" element={<Menu currentLocale={currentLocale}/>} />
          </Routes>
        </main>
        <Footer currentLocale={currentLocale}/>
      </div>
    </LocaleProvider>
  );
}

const App = () => (
  <Router>
    <AppWrapper />
  </Router>
);

export default App;
