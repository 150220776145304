import React from 'react';
import style from '../Stylesheets/TermsOfUse.module.css';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const TermsOfUsePage = ({currentLocale}) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  return (
    <div className={style.container}>
      <h1 className={style.title}>{translations.terms_of_use.title}</h1>
      <div className={style.content}>
        <p><strong>{translations.terms_of_use.content.introduction}</strong></p>
        <p>{translations.terms_of_use.content.introduction_text}</p>
        <p><strong>{translations.terms_of_use.content.definitions}</strong></p>
        <p>{translations.terms_of_use.content.definitions_text}</p>
        <p><strong>{translations.terms_of_use.content.use_of_website}</strong></p>
        <p>{translations.terms_of_use.content.use_of_website_text}</p>
        <p><strong>{translations.terms_of_use.content.intellectual_property_rights}</strong></p>
        <p>{translations.terms_of_use.content.intellectual_property_rights_text}</p>
        <p><strong>{translations.terms_of_use.content.privacy_policy}</strong></p>
        <p>{translations.terms_of_use.content.privacy_policy_text}</p>
        <p><strong>{translations.terms_of_use.content.disclaimer_of_warranties}</strong></p>
        <p>{translations.terms_of_use.content.disclaimer_of_warranties_text}</p>
        <p><strong>{translations.terms_of_use.content.limitation_of_liability}</strong></p>
        <p>{translations.terms_of_use.content.limitation_of_liability_text}</p>
        <p><strong>{translations.terms_of_use.content.applicable_law}</strong></p>
        <p>{translations.terms_of_use.content.applicable_law_text}</p>
        <p><strong>{translations.terms_of_use.content.changes_to_terms}</strong></p>
        <p>{translations.terms_of_use.content.changes_to_terms_text}</p>
        <p><strong>{translations.terms_of_use.content.contact_us}</strong></p>
        <p>{translations.terms_of_use.content.contact_us_text}</p>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
