import React from 'react';
import style from '../Stylesheets/Pages.module.css';
import photo1 from '../Pictures/Web_dev_pic.jpg';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const WebDevelopmentPage = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  return (
    <div>
      {/* Header section */}
      <div className={style.header}>
        <h1 className={style.headerTitle}>{translations.webDevelopment.header}</h1>
        <div className={style.headerDescriptionContainer}>
          <p className={style.headerDescription}>
            {translations.webDevelopment.description}&euro;.
          </p>
        </div>
      </div>

      <div className={style.header}>
          <img src={photo1} alt="Web_Development" className={style.image} />
      </div>  

      {/* Why Choose Orange Ice? section */}
      <div className={style.header}>
        <h2 className={style.headerTitle}>{translations.webDevelopment.whyChooseOrangeIce.title}</h2>
        <div className={style.headerDescriptionContainer}>
          {translations.webDevelopment.whyChooseOrangeIce.points.map((point, index) => (
            <p key={index} className={style.headerDescription}>
              {point}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WebDevelopmentPage;
