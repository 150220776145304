// LocaleContext.js
import React, { createContext, useContext } from 'react';

const LocaleContext = createContext();

export const LocaleProvider = ({ localization, children }) => (
  <LocaleContext.Provider value={{ localization }}>
    {children}
  </LocaleContext.Provider>
);

export const useLocale = () => useContext(LocaleContext);
