import React from 'react';
import style from '../Stylesheets/Pages.module.css';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const EcommercePage = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  /* Localization */
  const ecommerce = translations.ecommerce.ecommerce;
  const ecommerce_text = translations.ecommerce.ecommerce_text;
  const up_to_five_new_pages = translations.ecommerce.up_to_five_new_pages;
  const updating_prices = translations.ecommerce.updating_prices;
  const one_change_to_your_design = translations.ecommerce.one_change_to_your_design;
  const up_to_ten_new_pages = translations.ecommerce.up_to_ten_new_pages;
  const answering_customer_questions = translations.ecommerce.answering_customer_questions;
  const customer_service_24_7 = translations.ecommerce.customer_service_24_7;
  const up_to_three_changes_to_your_design = translations.ecommerce.up_to_three_changes_to_your_design;
  const up_to_fifteen_new_pages = translations.ecommerce.up_to_fifteen_new_pages;
  const creating_online_campaigns = translations.ecommerce.creating_online_campaigns;
  const analyzing_your_progress_and_informing_you_every_week = translations.ecommerce.analyzing_your_progress_and_informing_you_every_week;
  const make_your_own_price = translations.ecommerce.make_your_own_price;
  const work_with_us = translations.ecommerce.work_with_us;
  const month = translations.ecommerce.month;
  const up_to_seven_changes_to_your_design = translations.ecommerce.up_to_seven_changes_to_your_design;

  return (
    <div>
      <div className={style.header}>
        <h1 className={style.headerTitle}>{ecommerce}</h1>
        <div className={style.headerDescriptionContainer}>
          <p className={style.headerDescription}>
            {ecommerce_text}
          </p>
        </div>
      </div>
      <div className={style.rectanglesContainer}>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>399&euro;</h1>
            <span>{month}</span>
          </div>
          <ul className={style.list}>
            <li>{up_to_five_new_pages}</li>
            <li>{updating_prices}</li>
            <li>{one_change_to_your_design}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>999&euro;</h1>
            <span>{month}</span>
          </div>
          <ul className={style.list}>
            <li>{up_to_ten_new_pages}</li>
            <li>{updating_prices}</li>
            <li>{answering_customer_questions}</li>
            <li>{customer_service_24_7}</li>
            <li>{up_to_three_changes_to_your_design}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>1999&euro;</h1>
            <span>{month}</span>
          </div>
          <ul className={style.list}>
            <li>{up_to_fifteen_new_pages}</li>
            <li>{customer_service_24_7}</li>
            <li>{answering_customer_questions}</li>
            <li>{up_to_seven_changes_to_your_design}</li>
            <li>{creating_online_campaigns}</li>
            <li>{analyzing_your_progress_and_informing_you_every_week}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <h2>{make_your_own_price}</h2>
          <p>{work_with_us}</p>
        </div>
      </div>
    </div>
  );
}

export default EcommercePage;
