import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD28R1O8PwNd0gbWcAbO8CNvtpHtk-AnH0",
    authDomain: "orangeice-7cf37.firebaseapp.com",
    projectId: "orangeice-7cf37",
    storageBucket: "orangeice-7cf37.appspot.com",
    messagingSenderId: "1030877419156",
    appId: "1:1030877419156:web:2ac3b9384e525219c74681",
    measurementId: "G-528LHCJ50C"
  };
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  export {db};