import React from 'react';
import instagram from '../Pictures/Instagram_ico.png';
import facebook from '../Pictures/Facebook_ico.png';
import linkedin from '../Pictures/LinkedIn_ico.png';
import '../Stylesheets/Font.css';
import { Link } from 'react-router-dom';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const Footer = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;
  const handleTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const styles = {
    footer: {
      backgroundColor: '#333',
      color: '#fff',
      padding: '20px',
      fontFamily: 'Poppins, sans-serif',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    section: {
      marginBottom: '20px',
      textAlign: 'center',
    },
    sectionTitle: {
      fontWeight: 'bold',
    },
    socialIcons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      fontSize: '24px',
      marginRight: '10px',
    },
    backToTopButton: {
      backgroundColor: '#e3720d',
      padding: '10px 20px',
      borderRadius: '5px',
      marginTop: '20px',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
    },
    terms: {
      color: "#fff"
    },
  };
  /*Localization*/
  const contact = translations.footer.contact;
  const email = translations.footer.email;
  const phone = translations.footer.phone;
  // eslint-disable-next-line
  const address = translations.footer.address;
  const connect = translations.footer.connect;
  const rights = translations.footer.rights;
  const back_to_top = translations.footer.back_to_top;
  const terms = translations.footer.terms_of_use;
  
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        {/* Contact Information */}
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>{contact}</h3>
          <p>{email}</p>
          <p>{phone}</p>
          {/*<p>{address}</p>*/}
        </div>

        {/* Social Media Icons */}
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>{connect}</h3>
          <div style={styles.socialIcons}>
            <a href="https://www.facebook.com">
              <span role="img" aria-label="Facebook" style={styles.icon}>
                <img src={facebook} alt='Facebook' />
              </span>
            </a>
            <a href="https://www.instagram.com">
              <span role="img" aria-label="Instagram" style={styles.icon}>
                <img src={instagram} alt='Instagram' />
              </span>
            </a>
            <a href="https://www.linkedin.com/">
              <span role="img" aria-label="LinkdIn" style={styles.icon}>
                <img src={linkedin} alt='LinkedIn' />
              </span>
            </a>
            {/* Add more social media icons as needed */}
          </div>
        </div>
        
        {/* Copyright Information */}
        <div style={styles.section}>
          <p>&copy; {rights}</p>
          <p><Link to="/terms-of-use" style={styles.terms}>{terms}</Link></p>
        </div>

        {/* Back to Top Button */}
        <button style={styles.backToTopButton} onClick={handleTopButtonClick}>
          {back_to_top}
        </button>
      </div>
    </footer>
  );
};

export default Footer;
