// Services.js
import React from 'react';
import EcommerceSection from './EcommerceSection';
import SocialMediaSection from './SocialMediaSection';
import MarketingAdviceSection from './MarketingAdviceSection';
import WebsitesSection from './WebsitesSection';
import ApplicationDevelopmentSection from './ApplicationDevelopmentSection';
import VisualEngineering from './VisualEngineering';

const Services = ({ currentLocale, localization }) => {
  return (
    <div className="services-container">
      {/* Pass both currentLocale and localization props to the EcommerceSection */}
      <EcommerceSection currentLocale={currentLocale} localization={localization} />
      <SocialMediaSection currentLocale={currentLocale} localization={localization}/>
      <MarketingAdviceSection currentLocale={currentLocale} localization={localization}/>
      <WebsitesSection currentLocale={currentLocale} localization={localization}/>
      <ApplicationDevelopmentSection currentLocale={currentLocale} localization={localization}/>
      <VisualEngineering currentLocale={currentLocale} localization={localization}/>
    </div>
  );
};

export default Services;
