import React from 'react';
import style from '../Stylesheets/Pages.module.css';
import photo1 from '../Pictures/Visual_ing_pic.jpg';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const WebDevelopmentPage = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;
 
  return (
    <div>
      {/* Header section */}
      <div className={style.header}>
        <h1 className={style.headerTitle}>{translations.visualEngineering.header}</h1>
        <div className={style.headerDescriptionContainer}>
          <p className={style.headerDescription}>
            {translations.visualEngineering.description}
          </p>
        </div>
        <h1 className={style.headerTitle}>{translations.visualEngineering.pricing}</h1>
        <div className={style.headerDescriptionContainer}>
          <p className={style.headerDescription}>
            {translations.visualEngineering.pricing_description}
          </p>
        </div>
      </div>
  
      <div className={style.header}>
        <img src={photo1} alt="App_Development" className={style.image} />
      </div>  
  
      {/* Why Choose Orange Ice? section */}
      <div className={style.header}>
        <h2 className={style.headerTitle}>{translations.visualEngineering.whyChooseOrangeIce.title}</h2>
        <div className={style.headerDescriptionContainer}>
          {translations.visualEngineering.whyChooseOrangeIce.points.map((point, index) => (
            <p key={index} className={style.headerDescription}>
              {point}
            </p>
          ))}
        </div>
      </div>
    </div>
  );  
}

export default WebDevelopmentPage;
