import React, { useState, useEffect, useRef } from 'react';
import picture from "../Pictures/marketing_advice_menu.png";
import style from "../Stylesheets/e_commerce_menu.module.css"; // Import CSS module for styling

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const MarketingAdviceMenu = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  const marketingAdvice = translations.menu.marketingAdvice;

  const [middleReached, setMiddleReached] = useState(false);
  const pictureRef = useRef(null);
  const serviceTextRef = useRef(null); // Add a ref for the text element

  useEffect(() => {
    const handleScroll = () => {
      const pictureTop = pictureRef.current.getBoundingClientRect().top;
      const pictureBottom = pictureRef.current.getBoundingClientRect().bottom;
      const middleThreshold = window.innerHeight * 0.5; // Adjusted threshold

      // Check if the top of the image is above the middle threshold and the bottom is below
      const topReached = pictureTop < middleThreshold;
      const bottomReached = pictureBottom > middleThreshold;

      // Show the text when top of image is in the middle and hide it when bottom touches
      setMiddleReached(topReached && bottomReached);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={style.socialMediaMarketing}>
      {/* Picture */}
      <img ref={pictureRef} src={picture} alt="E-commerce Menu" className={style.picture}  style={{opacity: '100%'}}/>

      {/* "Social Media Marketing" text */}
      <div ref={serviceTextRef} className={`${style.serviceText} ${middleReached ? style.visible : ''}`} style={{ left: middleReached ? '80%' : '100%' }}>
        <h1>{marketingAdvice}</h1>
      </div>
    </div>
  );
}

export default MarketingAdviceMenu;
