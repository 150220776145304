import React from 'react';
import style from '../Stylesheets/Pages.module.css';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const SocialMediaMarketingPage = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  // Accessing localization constants
  const socialMediaMarketing = translations.socialMediaMarketing.socialMediaMarketing;
  const socialMediaMarketing_text = translations.socialMediaMarketing.socialMediaMarketing_text;
  const month = translations.socialMediaMarketing.month;
  const up_to_fifteen_posts = translations.socialMediaMarketing.up_to_fifteen_posts;
  const one_video = translations.socialMediaMarketing.one_video;
  const design_suggestions = translations.socialMediaMarketing.design_suggestions;
  const copywriting_for_your_posts = translations.socialMediaMarketing.copywriting_for_your_posts;
  const one_commercial = translations.socialMediaMarketing.one_commercial;
  const up_to_eighteen_posts = translations.socialMediaMarketing.up_to_eighteen_posts;
  const recording_reels = translations.socialMediaMarketing.recording_reels;
  const answering_messages = translations.socialMediaMarketing.answering_messages;
  const detailed_analysis = translations.socialMediaMarketing.detailed_analysis;
  const creating_online_campaigns = translations.socialMediaMarketing.creating_online_campaigns;
  const professional_copywriting_for_your_posts = translations.socialMediaMarketing.professional_copywriting_for_your_posts;
  const using_copyright_free_material = translations.socialMediaMarketing.using_copyright_free_material;
  const marketing_plan_creation = translations.socialMediaMarketing.marketing_plan_creation;
  const posts_every_day = translations.socialMediaMarketing.posts_every_day;
  const answering_questions = translations.socialMediaMarketing.answering_questions;
  const creation_of_online_campaigns = translations.socialMediaMarketing.creation_of_online_campaigns;
  const creation_of_email_campaigns = translations.socialMediaMarketing.creation_of_email_campaigns;
  const web_site_maintenance = translations.socialMediaMarketing.web_site_maintenance;
  const make_your_own_price = translations.socialMediaMarketing.make_your_own_price;
  const work_with_us = translations.socialMediaMarketing.work_with_us;

  return (
  <div>
          {/* Header section */}
        <div className={style.header}>
          <h1 className={style.headerTitle}>{socialMediaMarketing}</h1>
          <div className={style.headerDescriptionContainer}>
            <p className={style.headerDescription}>
              {socialMediaMarketing_text}
            </p>
        </div>

        </div>
        <div className={style.rectanglesContainer}>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>399&euro;</h1>
            <span>{month}</span>
          </div>
          <ul>
            <li>{up_to_fifteen_posts}</li>
            <li>{one_video}</li>
            <li>{design_suggestions}</li>
            <li>{copywriting_for_your_posts}</li>
            <li>{one_commercial}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>999&euro;</h1>
            <span>{month}</span>
          </div>
          <ul>
            <li>{up_to_eighteen_posts}</li>
            <li>{recording_reels}</li>
            <li>{answering_messages}</li>
            <li>{detailed_analysis}</li>
            <li>{creating_online_campaigns}</li>
            <li>{professional_copywriting_for_your_posts}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <div className={style.priceContainer}>
            <h1>1999&euro;</h1>
            <span>{month}</span>
          </div>
          <ul>
            <li>{using_copyright_free_material}</li>
            <li>{marketing_plan_creation}</li>
            <li>{posts_every_day}</li>
            <li>{answering_questions}</li>
            <li>{creation_of_online_campaigns}</li>
            <li>{creation_of_email_campaigns}</li>
            <li>{web_site_maintenance}</li>
          </ul>
        </div>
        <div className={style.rectangle}>
          <h2>{make_your_own_price}</h2>
          <p>{work_with_us}</p>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaMarketingPage;
