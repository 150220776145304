// Popup.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from './Firebase';
import { collection, addDoc, } from 'firebase/firestore';
import style from '../Stylesheets/Popup.module.css';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const Popup = ({ currentLocale, setCurrentLocale, currentPath }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  const terms1 = translations.popup.terms_of_use1;
  const terms2 = translations.popup.terms_of_use2;

  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    // Check if the user has already been registered in local storage
    const hasRegistered = localStorage.getItem('hasRegistered');
    if (hasRegistered) {
      setShowPopup(false); // Hide the popup if the user has already registered
    }
  }, []);

  const handleClose = async () => {
    setShowPopup(false);
  
    // Get geolocation
    let geolocation = null;
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        geolocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
      } catch (error) {
        console.error("Error getting geolocation:", error);
      }
    }
  
    const timestamp = Date.now();
  
    // Check if the user has already been registered in the database
    const hasRegistered = localStorage.getItem('hasRegistered');
    if (!hasRegistered) {
      // Add the user to the database if they haven't been registered yet
      await addDoc(collection(db, "PopupActions"), {
        action: "Closed",
        timestamp: timestamp,
        location: geolocation
      });
  
      // Set a flag in local storage indicating that the user has been registered
      localStorage.setItem('hasRegistered', 'true');
    }
  };

  const toggleLanguage = () => {
    setCurrentLocale(currentLocale === 'sr' ? 'en' : 'sr');
  };

  useEffect(() => {
    if (showPopup && currentPath !== '/terms-of-use') {
      // Disable scrolling everywhere except on the Terms of Use page
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling otherwise
      document.body.style.overflow = 'auto';
    }
  }, [showPopup, currentPath]);

  // Don't show the popup on the Terms of Use page
  if (currentPath === '/terms-of-use') {
    return null;
  }

  return (
    showPopup && (
      <div className={style.popup_overlay}>
        <div className={style.popup_container}>
          <div className={style.popup}>
            <p>
              {terms1} <Link to="/terms-of-use" className={style.link}>{terms2}</Link>
            </p>
            <button className={style.close} onClick={handleClose}>Zatvori</button>
            <div className={style.languageSwitch} onClick={toggleLanguage}>
              {currentLocale === 'sr' ? 'SR' : 'EN'}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Popup;
