import React from 'react';
import style from '../Stylesheets/Pages.module.css';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const MarketingAdvicePage = ({ currentLocale }) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;
  // Accessing localization constants
  const marketingAdvice = translations.marketingAdvice.marketingAdvice;
  const marketingAdvice_text = translations.marketingAdvice.marketingAdvice_text;
  const month = translations.marketingAdvice.month;
  const ideal_for_small_businesses = translations.marketingAdvice.ideal_for_small_businesses;
  const includes_foundational_marketing_strategies = translations.marketingAdvice.includes_foundational_marketing_strategies;
  const perfect_for_those_with_limited_budgets = translations.marketingAdvice.perfect_for_those_with_limited_budgets;
  const tailored_for_businesses_seeking_to_expand = translations.marketingAdvice.tailored_for_businesses_seeking_to_expand;
  const offers_comprehensive_market_analysis = translations.marketingAdvice.offers_comprehensive_market_analysis;
  const suited_for_businesses_aiming_for_steady_growth = translations.marketingAdvice.suited_for_businesses_aiming_for_steady_growth;
  const designed_for_established_businesses = translations.marketingAdvice.designed_for_established_businesses;
  const includes_advanced_marketing_techniques = translations.marketingAdvice.includes_advanced_marketing_techniques;
  const best_suited_for_businesses_committed_to_investing = translations.marketingAdvice.best_suited_for_businesses_committed_to_investing;
  const make_your_own_price = translations.marketingAdvice.make_your_own_price;
  const work_with_us = translations.marketingAdvice.work_with_us;

  return (
      <div>
        {/* Header section */}
        <div className={style.header}>
          <h1 className={style.headerTitle}>{marketingAdvice}</h1>
          <div className={style.headerDescriptionContainer}>
            <p className={style.headerDescription}>
              {marketingAdvice_text}
            </p>
          </div>
        </div>

        {/* Rectangles container */}
        <div className={style.rectanglesContainer}>
          <div className={style.rectangle}>
            <div className={style.priceContainer}>
              <h1>299&euro;</h1>
              <span>{month}</span>
            </div>
            <ul>
              <li>{ideal_for_small_businesses}</li>
              <li>{includes_foundational_marketing_strategies}</li>
              <li>{perfect_for_those_with_limited_budgets}</li>
            </ul>
          </div>
          <div className={style.rectangle}>
            <div className={style.priceContainer}>
              <h1>499&euro;</h1>
              <span>{month}</span>
            </div>
            <ul>
              <li>{tailored_for_businesses_seeking_to_expand}</li>
              <li>{offers_comprehensive_market_analysis}</li>
              <li>{suited_for_businesses_aiming_for_steady_growth}</li>
            </ul>
          </div>
          <div className={style.rectangle}>
            <div className={style.priceContainer}>
              <h1>700&euro;</h1>
              <span>{month}</span>
            </div>
            <ul>
              <li>{designed_for_established_businesses}</li>
              <li>{includes_advanced_marketing_techniques}</li>
              <li>{best_suited_for_businesses_committed_to_investing}</li>
            </ul>
          </div>
          <div className={style.rectangle}>
            <h2>{make_your_own_price}</h2>
            <p>{work_with_us}</p>
          </div>
        </div>
      </div>
  );
}

export default MarketingAdvicePage;
