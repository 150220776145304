import React, { useState, useEffect } from 'react';
import yourImage from '../Pictures/Home_transition.jpg'; // Import your image

const Scrolling_logo = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  // eslint-disable-next-line
  const [middleReached, setMiddleReached] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentScrollTop);

      // Calculate the middle of the screen with a threshold of 60% of viewport height
      const middleThreshold = window.innerHeight * 0.6;
      // Check if the scroll position is greater than or equal to the middle threshold
      setMiddleReached(currentScrollTop >= middleThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Style for the service image
  const serviceImageStyle = {
    width: '100%',
    height: '600px', // Adjust height as needed
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    transition: 'transform 0.3s ease', // Add transition for smooth movement
    transform: `translateY(-${scrollPosition * 0.5}px) scale(${1 - scrollPosition * 0.0008})`, // Adjust the values to control the scrolling effect
    marginBottom: '400px', // Add margin bottom to create space
  };

  return (
    <div className="services-container">
      {/* Service content */}
      <div className="service-content">
        {/* Your service content */}
      </div>

      {/* Footer */}
      <div className="footer" style={{ position: 'fixed' }}>
        {/* Your footer content */}
      </div>

      {/* Service image */}
      <div
        className="service-image"
        style={{ ...serviceImageStyle, backgroundImage: `url(${yourImage})`, zIndex: -1 }}
      ></div>
    </div>
  );
};

export default Scrolling_logo;
