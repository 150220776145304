import React from 'react';
import style from '../Stylesheets/About_us.module.css';
import photo from '../Pictures/about_us.jpg';
import photo2 from '../Pictures/about_us2.jpg';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const AboutUs = ({ currentLocale }) => {
    const translations = currentLocale === 'en' ? enTranslations : srTranslations;

    /*Localization*/
    const welcome = translations.about_us.welcome;
    const welcome_text = translations.about_us.welcome_text;
    const our_mission = translations.about_us.our_mission;
    const our_mission_text = translations.about_us.our_mission_text;
    const what_we_do = translations.about_us.what_we_do;
    const what_we_do_text = translations.about_us.what_we_do_text;
    const why_choose_us = translations.about_us.why_choose_us;
    const experience = translations.about_us.experience;
    const experience_text = translations.about_us.experience_text;
    const personalized_approach = translations.about_us.personalized_approach;
    const personalized_approach_text = translations.about_us.personalized_approach_text;
    const results_driven = translations.about_us.results_driven;
    const results_driven_text = translations.about_us.results_driven_text;
    const get_started = translations.about_us.get_started;
    const get_started_text = translations.about_us.get_started_text;

  return (
    <div className={style['about-us-container']}>
      <h2>{welcome}</h2>
      <p>
        {welcome_text}
      </p>
      <h3>{our_mission}</h3>
      <div className={style['about-us-photo']}>
        <img src={photo2} alt="About Us" className={style['about-us-image2']} />
      </div>
      <p>
        {our_mission_text}
      </p>
      <h3>{what_we_do}</h3>
      <p>
        {what_we_do_text}
      </p>
      <h3>{why_choose_us}</h3>
      <div className={style['about-us-photo']}>
        <img src={photo} alt="About Us" className={style['about-us-image']} />
      </div>
      <p>
        <strong>{experience}</strong> {experience_text}
      </p>
      <p>
        <strong>{personalized_approach}</strong> {personalized_approach_text}
      </p>
      <p>
        <strong>{results_driven}</strong> {results_driven_text}
      </p>
      <h3>{get_started}</h3>
      <p>
        {get_started_text}
      </p>
    </div>
  );
};

export default AboutUs;
