// Header.js
import React, { useState, useEffect } from 'react';
import style from '../Stylesheets/Logo.module.css';
import logo from '../Pictures/Logo1.jpg';
import { Link } from 'react-router-dom';
import sr from '../locales/sr.json';
import en from '../locales/en.json';

const Header = ({ currentLocale, setCurrentLocale }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleLanguage = () => {
    setCurrentLocale(currentLocale === 'sr' ? 'en' : 'sr');
  };

  useEffect(() => {
    if (!isMenuOpen) {
      document.body.classList.add('menuNotActive');
    } else {
      document.body.classList.remove('menuNotActive');
    }
  }, [isMenuOpen]);

  const localization = currentLocale === 'sr' ? sr : en;

  return (
    <header className={style.header}>
      <div className={style.logoContainer}>
        <img className={style.logoPicture} src={logo} alt='Logo'/>
      </div>
      <h1 className={style.name}>Orange Ice</h1>
      <div className={style.languageSwitch} onClick={toggleLanguage}>
        {currentLocale === 'sr' ? 'SR' : 'EN'}
      </div>
      <div className={style.menuButton} onClick={toggleMenu}>
        <div className={style.burger}></div>
        <div className={style.burger}></div>
        <div className={style.burger}></div>
      </div>
      <nav className={`${style.menu} ${isMenuOpen ? style.menuActive : ''}`}>
        <ul>
          <li>
            <Link to="/menu" style={{ textDecoration: 'none', color: '#fff' }}>{localization.menu.menu}</Link>
          </li> 
          <li>
            <Link to="/aboutus" style={{ textDecoration: 'none', color: '#fff' }}>{localization.header.aboutUs}</Link>
          </li>
          {/*
          <li>{localization.footer.contact}</li>
          */}
          <li>
            <Link to="/services" style={{ textDecoration: 'none', color: '#fff' }}>{localization.header.services}</Link>
          </li> 
        </ul>
      </nav>
    </header>
  );
}

export default Header;
