import React from 'react';
import { Link } from 'react-router-dom';
import picture from '../Pictures/powerpoint.jpg';

import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const EcommerceSection = ({currentLocale}) => {
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  // Access the title and description from the translations
  const title = translations.pages.visualEngineering.title;
  const description = translations.pages.visualEngineering.description;
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'inline-block', width: 'auto' }}>
        <Link to="/VisualEngineeringPage" style={{ textDecoration: 'none' }}>
          <section className="section">
            <div className="background-image">
              <img src={picture} alt="Visual_Engineering" />
            </div>
            <div className="sectionContent">
            <h2 className='styles.sectionTitle'>{title}</h2>
            <p className='sectionText'>{description}</p>
            </div>
          </section>
        </Link>
      </div>
    </div>
  );
}

export default EcommerceSection;
