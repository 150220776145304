import React from 'react';
import { Link } from 'react-router-dom';
import picture from '../Pictures/E_Commerce_pic.jpg';

// Import JSON files for translations
import enTranslations from '../locales/en.json';
import srTranslations from '../locales/sr.json';

const EcommerceSection = ({ currentLocale }) => {
  // Determine which translation to use based on the current locale
  const translations = currentLocale === 'en' ? enTranslations : srTranslations;

  // Access the title and description from the translations
  const title = translations.pages.ecommerce.title;
  const description = translations.pages.ecommerce.description;

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="section">
        <Link to="/ecommerce-page" style={{ textDecoration: 'none' }}>
          <div className="background-image">
            <img src={picture} alt="E-commerce" />
          </div>
          <div className="sectionContent">
            <h2 className='styles.sectionTitle'>{title}</h2>
            <p className='sectionText'>{description}</p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default EcommerceSection;
