import React from 'react';
import ScrollingLogo from './Scrolling_logo';
import EcommerceMenu from './Ecommerce_menu';
import SocialMediaMenu from './social_media_marketing_menu';
import MarketingAdviceMenu from './Marketing_advice_menu';
import WebDevelopmentMenu from './Web_development_menu';
import ApplicationDevelopmentMenu from './Application_development_menu';
import VisualEngineeringMenu from './Visual_engineering_menu';

const Menu = ({ currentLocale }) => {
  console.log("Current Language:", currentLocale);

  return (
    <div className="services-container">
      {/* Additional sections */}
      <ScrollingLogo />
      <EcommerceMenu currentLocale={currentLocale} />
      <SocialMediaMenu currentLocale={currentLocale} />
      <MarketingAdviceMenu currentLocale={currentLocale} />
      <WebDevelopmentMenu currentLocale={currentLocale} />
      <ApplicationDevelopmentMenu currentLocale={currentLocale} />
      <VisualEngineeringMenu currentLocale={currentLocale} />
    </div>
  );
};

export default Menu;
